import React from 'react'

import SEO from '../../components/seo'
import { Container, Row, Column } from '../../components/ui/layout/Grid'
import { ContentBox } from '../../components/ui/layout/ContentBox'
import { Cross } from '../../components/ui/Icons'
import Button from '../../components/ui/forms/Buttons'

const OrderSuccess = () =>
  <>
    <SEO title="Something went wrong :(" />
    <Container size="md">
      <Row
        css={`
          min-height: 65vh;
        `}
      >
        <Column>
          <ContentBox
            css={`
              text-align:center;
              svg {
                width: 100px;
                height: 100px;
              }
            `}
          >
            <Cross />
            <h1>Apologies something went wrong :(</h1>
            <p>Contact us at hello@investabit.com and we&apos;ll jump in to help!</p>
            <p css={'padding-bottom: 1em;'}>Meanwhile you can try registering again.</p>
            <Button
              to="/account/register/"
              appearance="primary"
            >
              Try Again
            </Button>
          </ContentBox>
        </Column>
      </Row>
    </Container>
  </>

export default OrderSuccess
